<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Online results report
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Online results report
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="2">
                  <v-text-field
                      label="Exam Key"
                      v-model="search.exam_key"
                      outlined
                      dense
                      @input="search.transaction_id = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-select
                      v-model="search.date_type"
                      :items="date_type_values"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                      label="Date type"
                  >

                  </v-select>
                </v-col>

                <v-col cols="12" sm="6" md="2" v-if="search.date_type=='single_date'">
                  <v-menu
                      ref="menuDate"
                      v-model="menuSingleDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.single_date"
                          label="Date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs"
                          :error="$v.search.single_date.$error"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.single_date"
                        outlined
                        dense
                        no-title
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        @input="menuSingleDate = false"
                    >
                    </v-date-picker>
                  </v-menu>

                </v-col>

                <v-col cols="12" sm="6" md="2" v-if="search.date_type=='date_range'">
                  <v-menu
                      ref="menuDate"
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="View from date"
                          readonly
                          outlined :error="$v.search.start_date.$error"
                          dense
                          clearable
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        outlined
                        dense
                        no-title
                        @input="menuStartDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                  <span class="text-danger" v-if="$v.search.start_date.$error">This information is required</span>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="search.date_type=='date_range'">
                  <v-menu
                      ref="menuDate"
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.end_date"
                          label="View up to date"
                          readonly
                          outlined
                          dense
                          clearable
                          v-bind="attrs" :error="$v.search.end_date.$error"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.end_date"
                        outlined
                        dense :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"

                        no-title
                        @input="menuEndDate = false"
                    >
                    </v-date-picker>
                  </v-menu>
                  <span class="text-danger" v-if="$v.search.end_date.$error">This information is required</span>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-if="currentUser.access_type!='score'">
                  <v-autocomplete
                      :items="scores"
                      v-model="search.score_id" :error="$v.search.score_id.$error"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense clearable></v-autocomplete>
                  <span class="text-danger" v-if="$v.search.score_id.$error">This information is required</span>
                </v-col>
                <v-col cols="12" :md="search.date_type == 'date_range' ? 4 : 6" class="text-right">
                  <v-btn @click="exportToCsv()" class="btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-file-csv</v-icon>&nbsp;
                    Export (CSV)
                  </v-btn>
                </v-col>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import EnrolmentYearService from "@/services/setting/enrolment-year/EnrolmentYearService";
import ScoreService from "@/services/score/score/ScoreService";
import ReportService from "@/services/report/ReportService";
import {required,email,numeric,requiredIf} from "vuelidate/lib/validators";

const report =new ReportService();
const score=new ScoreService();
const enrolmentYear=new EnrolmentYearService();
export default {
  validations:{
    search:{
      score_id:{
        required: requiredIf(function (nestedModel){
          return this.currentUser.access_type!='score';
        })
      },
      single_date:{
        required:requiredIf(function (nestedModel){
          return this.search.date_type=='single_date' && this.search.exam_key=='';
        }),
      },
      start_date:{required: requiredIf(function (nestedModel){
          return this.search.date_type=='date_range';
        })
      },
      end_date:{required:requiredIf(function (nestedModel){
          return this.search.date_type=='date_range';
        }),
      }
    }
  },
  data(){
    return{
      search:{
        date_type:'single_date',
        exam_key:'',
        score_id:'',
        start_date:'',
        single_date:'',
        end_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      menuSingleDate:false,
      menuStartDate:false,
      menuEndDate:false,
      enrolment_years:[],
      scores:[],
      date_type_values: [
        { name: 'Single date', value: 'single_date' },
        { name: 'Date range', value: 'date_range' },

      ],

    }
  },
  methods:{
    exportToCsv(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if(this.currentUser.access_type=='score'){
          this.search.score_id=this.currentUser.score_id;
        }
        report
            .exportScoreExamReportToCsv(this.search)
      }
    },
    getAllScore(){
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {

          });
    },
  },
  mounted() {
    this.getAllScore();
  },
  computed:{
    currentUser() {
      return this.$store.getters.currentUser;
    },
  }
}
</script>
